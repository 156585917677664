import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

const SchemaReview = ({ rating, author, content }) => {
  const contactDetails = useStaticQuery(
    graphql`
      {
        global: sanityGlobalSettings {
          streetAddress
          addressLocality
          addressRegion
          postalCode
          hotelImage {
            asset {
              gatsbyImage(width: 1200, height: 600)
            }
          }
        }
        header: sanityGlobalHeader {
          tel
        }
      }
    `
  )

  const review = {
    '@context': 'https://schema.org/',
    '@type': 'Review',
    itemReviewed: {
      '@type': 'Hotel',
      name: 'Soul Valley',
      priceRange: '€€€',
      image: contactDetails.global.hotelImage.asset.gatsbyImage.images.fallback.src,
      telephone: contactDetails.header.tel,
      address: {
        '@type': 'PostalAddress',
        streetAddress: contactDetails.global.streetAddress,
        addressLocality: contactDetails.global.addressLocality,
        addressRegion: contactDetails.global.addressRegion,
        postalCode: contactDetails.global.postalCode,
        addressCountry: 'IT',
      },
    },
    reviewRating: {
      '@type': 'Rating',
      ratingValue: rating,
    },
    name: `Soul Valley Hotel Review by ${author}`,
    author: {
      '@type': 'Person',
      name: author,
    },
    reviewBody: content,
    publisher: {
      '@type': 'Person',
      name: author,
    },
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(review) }}
    />
  )
}

export default SchemaReview

SchemaReview.propTypes = {
  rating: PropTypes.number,
  author: PropTypes.string,
  content: PropTypes.string,
}

SchemaReview.defaultProps = {
  rating: '',
  author: '',
  content: '',
}
